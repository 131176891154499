/* Commerce Layer integration */
import { v1 as uuidv1 } from "uuid";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
    createAddress,
    createCustomer,
    createCustomerAddress,
    deleteCustomerAddress,
    getAddress,
    getCustomerAddresses,
    updateAddress,
    updateCustomer,
} from "./client_cl";
import { addMessage } from "../alerts/messagesSlice";

export const useAddress = (id = ``, queryOptions) => {
    return useQuery([`addresses`, id], () => getAddress(id), queryOptions);
};

export const useCreateAddress = () => {
    // Directus API checks for existing draft/pending address for this market/seller before creating a new one.
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(createAddress, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed creating address: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (address) => {
            queryClient.invalidateQueries(`addresses`).then();
        },
    });
};

export const useUpdateAddress = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(updateAddress, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed updating address: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(`addresses`).then();
        },
    });
};

export const useCreateCustomer = () => {
    return useMutation(createCustomer, {
        onError: (err) => {},
    });
};

export const useUpdateCustomer = () => {
    return useMutation(updateCustomer, {
        onError: (err) => {},
    });
};

export const useCustomerAddresses = () => {
    return useQuery([`customer_addresses`], getCustomerAddresses);
};

export const useCreateCustomerAddress = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(createCustomerAddress, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed creating customer addresses: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(`customer_addresses`).then();
        },
    });
};

export const useDeleteCustomerAddress = ({ customerAddressId: id }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(deleteCustomerAddress, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed deleting address: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(`customer_addresses`).then();
        },
    });
};
