import * as React from "react";
import PropTypes from "prop-types";
import { languages } from "../utils/language";
import { allCountries } from "../utils/geodata";
import { ClickableTooltip } from "../common/ClickableTooltip";

export const FlagIcon = ({ className, code, type = `lang` }) => {
    const DEFAULT_CDN_URL = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/flags/4x3/`;
    let country, countryName, countryCode, language;
    const splitCode = code.split(`-`);
    splitCode[0] = splitCode[0].toLowerCase();
    const fullCode = splitCode.join(`-`);
    switch (type) {
        case `lang`:
            countryCode =
                (languages[fullCode] && languages[fullCode].countryCode) ||
                `xx`;
            language =
                (languages[fullCode] && languages[fullCode].name) || code;
            return (
                <ClickableTooltip title={language}>
                    <img
                        alt={language}
                        aria-label={language}
                        className={`border border-gray-500 h-4 inline ${className}`}
                        src={
                            DEFAULT_CDN_URL + countryCode.toLowerCase() + `.svg`
                        }
                    />
                </ClickableTooltip>
            );
        case `country`:
            country = allCountries.find((country) => country.abbr === code);
            countryName = country && country.name ? country.name : code;
            return (
                <ClickableTooltip title={countryName}>
                    <img
                        alt={countryName}
                        aria-label={countryName}
                        className={`border border-gray-500 h-4 inline ${className}`}
                        src={DEFAULT_CDN_URL + code.toLowerCase() + `.svg`}
                    />
                </ClickableTooltip>
            );
        default:
            return null;
    }
};

FlagIcon.propTypes = {
    className: PropTypes.string,
    code: PropTypes.string.isRequired,
    type: PropTypes.oneOf([`lang`, `country`]),
};
