import * as React from "react";
import { Grid, List } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { OrderItem } from "./OrderItem";
import { useUser } from "../users/hooks";
import { useOrderMarket } from "./hooks";

export const OrderItems = ({ lineItems, order }) => {
    const { isLoading, data: market } = useOrderMarket(order && order.id);

    return (
        <>
            <Grid item xs={12}>
                <h3 className={`mt-4`}>
                    {market && !isLoading ? (
                        <Seller
                            sellerId={Number(market.attributes.reference)}
                        />
                    ) : (
                        <Skeleton />
                    )}
                </h3>
            </Grid>
            <Grid item xs={12}>
                {lineItems ? (
                    <List className={`flex flex-col`}>
                        {lineItems.map((item) => (
                            <OrderItem item={item} key={item.id} />
                        ))}
                    </List>
                ) : (
                    <Skeleton animation="pulse" height={400} variant="rect" />
                )}
            </Grid>
        </>
    );
};

const Seller = ({ sellerId }) => {
    const { isLoading, data: seller } = useUser(sellerId);
    return seller && !isLoading ? (
        `Seller: ` + seller.data.screen_name
    ) : (
        <Skeleton />
    );
};
