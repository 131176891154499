import * as React from "react";
import { navigate } from "gatsby";
import { Button, Paper } from "@material-ui/core";
import { SEO } from "../../../components/seo";
import { AddressFields } from "../../../common/AddressFields";
import { useUser } from "../../users/hooks";
import {
    useAddress,
    useCreateAddress,
    useCreateCustomerAddress,
    useUpdateAddress,
} from "../../account/hooks_cl";
import { Heading } from "../../../components/heading";
import { useOrderCustomer, useUpdateOrder } from "../../orders/hooks";
import { useCart } from "../../cart/hooks";

export const AddressScreen = ({ id, type }) => {
    const title = !id ? `Add Address` : `Edit Address`;
    const { data: user } = useUser();
    const { data: address } = useAddress(id, { enabled: !!id });
    const { data: orders } = useCart();
    const { data: customer } = useOrderCustomer(
        orders && orders.data[0] && orders.data[0].id
    );

    const initialState = {
        firstname: ``,
        surname: ``,
        company: ``,
        address1: ``,
        address2: ``,
        city: ``,
        county: ``,
        postcode: ``,
        country: `US`,
        phone: ``,
    };
    const [state, setState] = React.useState(initialState);
    const handleChange = (event) => {
        if (event.target.name === `company`) {
            setState({ ...state, [event.target.name]: event.target.value });
        } else {
            setState({ ...state, [event.target.name]: event.target.value });
        }
    };
    const mutationCreate = useCreateAddress();
    const mutationUpdate = useUpdateAddress();
    const orderUpdateMutation = useUpdateOrder();
    const customerAddressMutation = useCreateCustomerAddress();
    React.useEffect(() => {
        if (address && address.data) {
            let addr = address.data.attributes;
            setState((s) => {
                return {
                    ...s,
                    firstname: addr.first_name || ``,
                    surname: addr.last_name || ``,
                    company: addr.company || ``,
                    address1: addr.line_1 || ``,
                    address2: addr.line_2 || ``,
                    city: addr.city || ``,
                    county: addr.state_code || ``,
                    postcode: addr.zip_code || ``,
                    country: addr.country_code || ``,
                    phone: addr.phone || ``,
                };
            });
        } else if (user && user.data) {
            setState((s) => {
                return {
                    ...s,
                    country: user.data.country || ``,
                    phone: user.data.phone1 || ``,
                };
            });
        }
    }, [address, user]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (address && address.data) {
            mutationUpdate.mutate(
                { id: address.data.id, updates: state },
                { onSuccess: () => navigate(`/checkout`) }
            );
        } else {
            if (type === `shipping` || type === `billing`) {
                mutationCreate.mutate(state, {
                    onSuccess: (address) => {
                        // Add to order
                        orderUpdateMutation.mutate(
                            {
                                id: orders.data[0].id,
                                updates: { billing_address_id: address.id },
                            },
                            {
                                onSuccess: () => navigate(`/checkout`),
                            }
                        );
                        // Save to customer address book
                        customerAddressMutation.mutate({
                            customerId: customer.id,
                            addressId: address.id,
                        });
                    },
                });
            } else {
                navigate(`/checkout`);
            }
        }
    };

    return (
        <section className={`max-w-screen-sm mx-auto`}>
            <SEO title="Checkout: Shipping" />
            <Paper className={`min-h-screen p-4`}>
                <Heading text={title} />
                <form onSubmit={handleSubmit}>
                    <AddressFields
                        handleChange={handleChange}
                        setState={setState}
                        state={state}
                    />
                    <Button
                        className={`mt-4`}
                        color="primary"
                        type="submit"
                        variant="contained"
                    >
                        Save
                    </Button>
                </form>
            </Paper>
        </section>
    );
};
