import * as React from "react";
import { ClickAwayListener, Tooltip } from "@material-ui/core";

export const ClickableTooltip = ({ children, title }) => {
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip onClose={handleTooltipClose} open={open} title={title}>
                <button
                    onClick={handleTooltipOpen}
                    onFocus={handleTooltipOpen}
                    onMouseOver={handleTooltipOpen}
                >
                    {children}
                </button>
            </Tooltip>
        </ClickAwayListener>
    );
};
