import * as React from "react";
import {
    useOrderAvailablePaymentMethods,
    useUpdateOrder,
} from "../orders/hooks";

export const AvailablePaymentMethods = ({ order }) => {
    const { data: paymentMethods } = useOrderAvailablePaymentMethods(
        order && order.id
    );
    const { mutate: mutateOrder } = useUpdateOrder();

    React.useEffect(() => {
        if (order && order.id && paymentMethods) {
            mutateOrder({
                id: order.id,
                updates: { payment_method_id: paymentMethods.data[0].id },
            });
        }
    }, [mutateOrder, order, paymentMethods]);

    return null;
};
