// TODO: Gatsby retrieval of Conditions from API
const cardCondition = {
    1: `Mint`,
    2: `Near mint`,
    3: `Lightly played`,
    4: `Moderately played`,
    5: `Heavily played`,
    6: `Damaged`,
    7: `Any`,
};

const cardConditionsByList = (listName) => {
    const conditions = { ...cardCondition };
    if ([`MyCollection`, `OfferForTrade`, `Selling`].includes(listName)) {
        delete conditions[7];
    }
    return conditions;
};

export { cardCondition, cardConditionsByList };
