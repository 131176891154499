import * as React from "react";
import { Grid, List } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { OrderItem } from "./OrderItem";
import { useUser } from "../users/hooks";
import { useOrder, useOrderMarket, useOrderShippingAddress } from "./hooks";
import { Address } from "../checkout/Address";
import { LocalPrice } from "../../common/LocalPrice";
import { SEO } from "../../components/seo";
import { Heading } from "../../components/heading";
import { orderOverallStatus } from "./utils";

const getItems = (order) => {
    return order.included.filter(
        (item) =>
            item.attributes.item_type === `skus` && !!item.attributes.sku_code
    );
};

export const OrderDetails = ({ orderId }) => {
    const { isLoading: isLoadingMarket, data: market } =
        useOrderMarket(orderId);
    const { isLoading: isLoadingShippingAddress, data: shippingAddress } =
        useOrderShippingAddress(orderId);
    const { isLoading: isLoadingOrder, data: order } = useOrder(orderId);
    const orderData = order && order.data;

    return (
        <section>
            <SEO title="Order details" />
            <Heading className={`mb-4`} text="Order details" />
            {orderData ? (
                <Grid container>
                    <Grid item xs={12}>
                        <ul className={`mt-2`}>
                            <li>Order #{orderData.attributes.number}</li>
                            <li className="capitalize font-bold">
                                Status:{` `}
                                {
                                    orderOverallStatus(orderData.attributes)
                                        .status
                                }
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <h3 className={`mt-4`}>
                                {market && !isLoadingMarket ? (
                                    <Seller
                                        sellerId={Number(
                                            market.attributes.reference
                                        )}
                                    />
                                ) : (
                                    <Skeleton />
                                )}
                            </h3>
                        </Grid>
                        <Grid item xs={12}>
                            {getItems(order) ? (
                                <List className={`flex flex-col`}>
                                    {getItems(order).map((item) => (
                                        <OrderItem item={item} key={item.id} />
                                    ))}
                                </List>
                            ) : (
                                <Skeleton
                                    animation="pulse"
                                    height={400}
                                    variant="rect"
                                />
                            )}
                        </Grid>
                        <ol className={`mb-8`}>
                            <li>
                                Postage{` `}
                                <LocalPrice
                                    className="float-right"
                                    fromCurrencyCode={
                                        orderData.attributes.currency_code
                                    }
                                    fromPrice={
                                        orderData.attributes
                                            .shipping_amount_cents
                                    }
                                />
                            </li>
                            <li>
                                Order total{` `}
                                <LocalPrice
                                    className="float-right"
                                    fromCurrencyCode={
                                        orderData.attributes.currency_code
                                    }
                                    fromPrice={
                                        orderData.attributes
                                            .total_amount_with_taxes_cents
                                    }
                                />
                            </li>
                        </ol>
                    </Grid>
                    <Grid className="pr-4" container item xs={8}>
                        <Grid item md={4} sm={6} xs={12}>
                            <h3 className="font-bold">Shipping address</h3>
                        </Grid>
                        <Grid item md={8} sm={6} xs={12}>
                            {isLoadingShippingAddress ? (
                                <Skeleton
                                    animation="pulse"
                                    height={100}
                                    variant="rect"
                                />
                            ) : (
                                <Address data={shippingAddress} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            ) : isLoadingOrder ? (
                <Skeleton animation="pulse" height={500} variant="rect" />
            ) : (
                <p className={`mt-4 text-lg`}>
                    Unknown order. Please contact us if you think you should be
                    seeing details of your order here.
                </p>
            )}
        </section>
    );
};

const Seller = ({ sellerId }) => {
    const { isLoading, data: seller } = useUser(sellerId);
    return seller && !isLoading ? (
        `Seller: ` + seller.data.screen_name
    ) : (
        <Skeleton />
    );
};
