// https://docs.commercelayer.io/developers/resources/orders
// status, payment_status, fulfillment_status
// placed_at, approved_at, cancelled_at, payment_updated_at, fulfillment_updated_at, refreshed_at, archived_at, expires_at, created_at, updated_at

const orderOverallStatus = (orderData) => {
    if (orderData.archived_at !== null) {
        return { status: `archived`, updated_at: orderData.archived_at };
    } else if (orderData.cancelled_at) {
        return { status: `cancelled`, updated_at: orderData.cancelled_at };
    } else if (orderData.fulfillment_status === `in_progress`) {
        return {
            status: `preparing`,
            updated_at: orderData.fulfillment_updated_at,
        };
    } else if (orderData.fulfillment_status === `fulfilled`) {
        return {
            status: `shipped`,
            updated_at: orderData.fulfillment_updated_at,
        };
    } else {
        return { status: `unknown`, updated_at: orderData.updated_at };
    }
};

export { orderOverallStatus };
