import * as React from "react";
import { navigate } from "gatsby";
import { Button, Grid, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Heading } from "../../../components/heading";
import { SEO } from "../../../components/seo";
import { OrderItems } from "../../orders/OrderItems";
import { Address } from "../Address";
import { ShippingOptions } from "../ShippingOptions";
import { useCart } from "../../cart/hooks";
import { LocalPrice } from "../../../common/LocalPrice";
import { AddressManager } from "../../account/AddressManager";
import {
    useOrderBillingAddress,
    useOrderShippingAddress,
    useUpdateOrder,
} from "../../orders/hooks";
import { AvailablePaymentMethods } from "../AvailablePaymentMethods";
import { PayPalButton } from "../PayPalButton";

export const ReviewScreen = () => {
    const { isLoading: isLoadingOrders, data: orders } = useCart();
    const orderData = orders && orders.data[0];
    const { isLoading: isLoadingBilling, data: billingAddress } =
        useOrderBillingAddress(orderData && orderData.id);
    const { isLoading: isLoadingShipping, data: shippingAddress } =
        useOrderShippingAddress(orderData && orderData.id);
    const orderUpdateMutation = useUpdateOrder();
    const [managingAddress, setManagingAddress] = React.useState(``);
    //const { paymentGatewayIsLoading, data:paymentGateway } = usePaymentGateways({filters:{u: userId}, enabled:!!userId});
    //const { paymentMethodIsLoading, data:paymentMethod } = usePaymentMethods({filters:{u: userId}, enabled:!!userId});
    React.useEffect(() => {
        if (orders && orders.data.length === 0) {
            navigate(`/marketplace`, { replace: true });
        }
    }, [orders]);
    const handleUseBillingAddress = (addressId) => {
        orderUpdateMutation.mutate({
            id: orderData.id,
            updates: {
                billing_address_id: addressId,
                _save_billing_address_to_customer_address_book: true,
                _shipping_address_same_as_billing: false,
            },
        });
        setManagingAddress(``);
    };
    const handleUseShippingAddress = (addressId) => {
        orderUpdateMutation.mutate({
            id: orderData.id,
            updates: {
                shipping_address_id: addressId,
                _save_shipping_address_to_customer_address_book: true,
                _billing_address_same_as_shipping: false,
            },
        });
        setManagingAddress(``);
    };
    const readyToPay =
        orders && orders.data.length > 0 && billingAddress && shippingAddress; // TODO: && there's valid shipping
    const getItems = (order) => {
        return orders.included.filter((item) =>
            order.relationships.line_items.data.some(
                (li) => li.id === item.id && !!item.attributes.sku_code
            )
        );
    };

    return orders && orders.data.length > 0 ? (
        <Grid className="mb-4" container spacing={4}>
            <Grid item lg={9} md={8} sm={6} xs={12}>
                <section>
                    <SEO title="Checkout" />
                    {orders.data.length > 1 && (
                        <Paper className={`mb-4 p-4`}>
                            <Heading
                                text={`${orders.data.length} Pending orders`}
                            />
                            <Grid container>
                                <p>
                                    Your cart has items from multiple sellers.
                                    Currently you need to pay one seller at a
                                    time.
                                </p>
                            </Grid>
                        </Paper>
                    )}
                    <Paper className={`mb-4 p-4`}>
                        {!managingAddress ? (
                            <Grid container spacing={2}>
                                <Grid className="flex-col" item md={6} xs={12}>
                                    <Heading text="Billing address" />
                                    {isLoadingBilling ? (
                                        <Skeleton
                                            animation="pulse"
                                            height={130}
                                            variant="rect"
                                        />
                                    ) : billingAddress && billingAddress.id ? (
                                        <>
                                            <Address data={billingAddress} />
                                            <Button
                                                className="mt-2"
                                                color="primary"
                                                onClick={() =>
                                                    setManagingAddress(
                                                        `billing`
                                                    )
                                                }
                                                size="small"
                                            >
                                                Use another address
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            className="mt-6"
                                            color="primary"
                                            onClick={() =>
                                                setManagingAddress(`billing`)
                                            }
                                            size="small"
                                            variant="contained"
                                        >
                                            Choose address
                                        </Button>
                                    )}
                                </Grid>
                                <Grid className="flex-col" item md={6} xs={12}>
                                    <Heading text="Shipping address" />
                                    {isLoadingShipping ? (
                                        <Skeleton
                                            animation="pulse"
                                            height={130}
                                            variant="rect"
                                        />
                                    ) : shippingAddress &&
                                      shippingAddress.id ? (
                                        <>
                                            <Address data={shippingAddress} />
                                            <Button
                                                className="mt-2"
                                                color="primary"
                                                onClick={() =>
                                                    setManagingAddress(
                                                        `shipping`
                                                    )
                                                }
                                                size="small"
                                            >
                                                Use another address
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            className="mt-6"
                                            color="primary"
                                            onClick={() =>
                                                setManagingAddress(`shipping`)
                                            }
                                            size="small"
                                            variant="contained"
                                        >
                                            Choose address
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        ) : (
                            <AddressManager
                                addressType={managingAddress}
                                handleUseAddress={
                                    managingAddress === `billing`
                                        ? handleUseBillingAddress
                                        : handleUseShippingAddress
                                }
                            />
                        )}
                    </Paper>
                    <Paper className={`mb-4 p-4`}>
                        <Heading text="Review items and postage" />
                        {!isLoadingOrders && orders ? (
                            <Grid container>
                                <AvailablePaymentMethods order={orderData} />
                                <OrderItems
                                    lineItems={getItems(orderData)}
                                    order={orderData}
                                />
                                <ShippingOptions order={orderData} />
                            </Grid>
                        ) : (
                            <Skeleton
                                animation="pulse"
                                height={400}
                                variant="rect"
                            />
                        )}
                    </Paper>
                </section>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Paper className="p-4">
                    {!isLoadingOrders && orders ? (
                        <ol>
                            <li className="">
                                Items ({orderData.attributes.skus_count})
                                <LocalPrice
                                    className="float-right"
                                    fromCurrencyCode={
                                        orderData.attributes.currency_code
                                    }
                                    fromPrice={
                                        orderData.attributes
                                            .subtotal_amount_cents
                                    }
                                />
                            </li>
                            <li className="pb-3 pt-1.5">
                                Postage{` `}
                                <LocalPrice
                                    className="float-right"
                                    fromCurrencyCode={
                                        orderData.attributes.currency_code
                                    }
                                    fromPrice={
                                        orderData.attributes
                                            .shipping_amount_cents
                                    }
                                />
                            </li>
                            <li className="border-t-2 border-opacity-40 leading-tight py-3 text-lg">
                                Order total{` `}
                                <LocalPrice
                                    className="float-right"
                                    fromCurrencyCode={
                                        orderData.attributes.currency_code
                                    }
                                    fromPrice={
                                        orderData.attributes
                                            .total_amount_with_taxes_cents
                                    }
                                />
                            </li>
                        </ol>
                    ) : (
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                    )}
                    <PayPalButton disabled={!readyToPay} order={orderData} />
                </Paper>
            </Grid>
        </Grid>
    ) : null;
};
