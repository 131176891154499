import * as React from "react";
import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useShippingMethods } from "../../hooks/useCommerceLayer";
import { LocalPrice } from "../../common/LocalPrice";
import { codesToNames } from "../../utils/countries";
import {
    useOrderMarket,
    useOrderShipments,
    useUpdateShipment,
} from "../orders/hooks";
import { useQueryClient } from "react-query";
import { Link } from "gatsby";

export const ShippingOptions = ({ order }) => {
    const countryCode =
        order && order.attributes && order.attributes.country_code;
    const queryClient = useQueryClient();
    const { isLoading: isLoadingOrderMarket, data: market } = useOrderMarket(
        order && order.id
    );
    const { mutate: mutateShipment } = useUpdateShipment();
    const sellerId = market && Number(market.attributes.reference);
    const filters = {
        Api: {
            referenceEq: sellerId,
            nameEnd: countryCode,
        },
    };
    const { isLoading: isLoadingShippingMethods, data: shippingMethods } =
        useShippingMethods({ filters });
    const shippingMethodId =
        shippingMethods &&
        shippingMethods.data &&
        shippingMethods.data[0] &&
        shippingMethods.data[0].id;
    const { data: shipments } = useOrderShipments(order && order.id);
    const shipmentId =
        shipments &&
        shipments.data &&
        shipments.data[0] &&
        shipments.data[0].id;
    React.useEffect(() => {
        if (shippingMethodId && shipmentId) {
            mutateShipment({
                id: shipmentId,
                updates: { shipping_method_id: shippingMethodId },
            });
        }
    }, [mutateShipment, order, queryClient, shipmentId, shippingMethodId]);

    if (!order) {
        return (
            <p>
                We're having trouble retrieving your order details. Please try
                refreshing the page.
            </p>
        );
    }

    return (
        <ListItem>
            <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid container item xs={7}>
                    <Grid item xs={12}>
                        <h4 className={`text-lg`}>Postage</h4>
                        <List>
                            {!countryCode ? (
                                <p>
                                    Please select a shipping address to view
                                    postage options.
                                </p>
                            ) : isLoadingShippingMethods ||
                              isLoadingOrderMarket ? (
                                <Skeleton
                                    animation="pulse"
                                    height={100}
                                    variant="rect"
                                />
                            ) : shippingMethods &&
                              shippingMethods.data.length > 0 ? (
                                shippingMethods.data.map((option) => (
                                    <ListItem
                                        alignItems="flex-start"
                                        className=""
                                        key={option.id}
                                    >
                                        <ListItemIcon>
                                            <Radio
                                                checked
                                                disableRipple
                                                edge="start"
                                                inputProps={{
                                                    "aria-labelledby":
                                                        option.id,
                                                }}
                                                tabIndex={-1}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={option.id}
                                            primary={`Standard shipping`}
                                            secondary={
                                                <LocalPrice
                                                    className={`font-bold`}
                                                    fromCurrencyCode={
                                                        order.attributes
                                                            .currency_code
                                                    }
                                                    fromPrice={
                                                        option.attributes
                                                            .price_amount_cents
                                                    }
                                                />
                                            }
                                        />
                                    </ListItem>
                                ))
                            ) : (
                                <p>
                                    We're sorry, this seller doesn't offer
                                    shipping to {codesToNames([countryCode])}.
                                    You could try{` `}
                                    <Link
                                        className="text-primary hover:text-primary-light"
                                        to={`/dashboard/messages/new/${sellerId}`}
                                    >
                                        contacting the seller directly
                                    </Link>
                                    .
                                </p>
                            )}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
};
