import * as React from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar } from "@material-ui/core";

export const Header = ({ siteTitle = `` }) => {
    return (
        <div>
            <AppBar color={`inherit`} elevation={1} position="static">
                <Toolbar>
                    <img
                        alt="CCG Trader"
                        className={`absolute h-full py-2 top-0`}
                        src="/images/ui/logo-no-strapline.svg"
                    />
                    <div className={`text-center text-xl w-full`}>Checkout</div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};
