import * as React from "react";
import * as PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { useCreatePaypalPayment } from "../orders/hooks";
import { Spinner } from "../../components/Spinner";

export const PayPalButton = ({ disabled, order }) => {
    // Approval url expires in 3 hours. So don't create in advance.
    const { mutate } = useCreatePaypalPayment();
    const [processing, setProcessing] = React.useState(false);

    const handlePaymentApproval = () => {
        setProcessing(true);
        // Create payment source
        mutate(
            { orderId: order.id },
            {
                onSuccess: async (result, variables) => {
                    if (result && result.attributes.approval_url) {
                        window.location.href = result.attributes.approval_url;
                    }
                },
            }
        );
    };

    return processing ? (
        <Spinner />
    ) : (
        <Button
            className="mt-2"
            color="primary"
            disabled={processing || disabled}
            fullWidth
            onClick={handlePaymentApproval}
            variant="contained"
        >
            Pay with PayPal / Card
        </Button>
    );
};

PayPalButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    order: PropTypes.object,
};
