import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { Footer } from "../../common/Footer";
import { LowPriorityMessage } from "../alerts/LowPriorityMessage";
import { Header } from "./Header";

export function Layout({ children }) {
    const { title } = useSiteMetadata();
    const useStyles = makeStyles((theme) => ({
        themeRoot: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.contrastText,
        },
    }));

    const classes = useStyles();

    return (
        <div
            className={`flex flex-col min-h-screen ${classes.themeRoot}`}
            id="layout"
        >
            <LowPriorityMessage />
            <Header siteTitle={title} />

            <section className={`p-4 sm:p-8`}>{children}</section>

            <Footer />
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};
